export const APP_VERSION = '0.0.1';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const sentryConfig = {
  dsn: '',
  traceSampleRate: 1.0,
  release: APP_VERSION,
};

export const API = {
  URL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080/'
      : 'https://europe-west2-sevimli-dostlar-2f01f.cloudfunctions.net/api',
  SAPP: 'k_2sLwQCzcoFM3',
};
