import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { FIREBASE_API } from 'src/config';
import { getFirestore } from 'firebase/firestore';

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const STORAGE = getStorage(firebaseApp);

export { AUTH, DB, STORAGE };

export const getStorageDownloadURL = path => {
  const fileRef = ref(STORAGE, path);

  return getDownloadURL(fileRef);
};
