import React, { lazy, Suspense } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import TicketLayout from 'src/layouts/TicketLayout';

const Loadable = Component => props => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  let element = useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to="/login" replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'ticket/:language/:ticketId',
          element: (
            <MainLayout>
              <TicketPlayer />
            </MainLayout>
          ),
        },
        {
          path: 'app',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: <Navigate to="/app/overview/dashboard" replace />,
              index: true,
            },
            {
              path: 'overview',
              children: [
                {
                  element: <Navigate to="/app/overview/dashboard" replace />,
                  index: true,
                },
                { path: 'dashboard', element: <Dashboard /> },
                { path: 'activities', element: <Activity /> },
                { path: 'transactions', element: <Transaction /> },
                {
                  path: 'charts',
                  children: [
                    {
                      element: (
                        <Navigate to="/app/overview/charts/revenue" replace />
                      ),
                      index: true,
                    },
                    { path: 'revenue', element: <ChartsRevenue /> },
                    { path: 'mrr', element: <ChartsMRR /> },
                    { path: 'new-trials', element: <ChartsNewTrials /> },
                    {
                      path: 'active-subscriber-base',
                      element: <ChartsActiveSubscriberBase />,
                    },
                    {
                      path: 'trial-start-conversion',
                      element: <ChartsTrialStartConversion />,
                    },
                    {
                      path: 'trial-to-paid-conversion',
                      element: <ChartsTrialToPaidConversion />,
                    },
                    {
                      path: 'subscription-conversion',
                      element: <ChartsSubscriptionConversion />,
                    },
                    { path: 'churn', element: <ChartsChurn /> },
                  ],
                },
              ],
            },
            {
              path: 'management',
              children: [
                {
                  element: <Navigate to="/app/management/users" replace />,
                  index: true,
                },
                {
                  path: 'users',
                  element: <UserListView />,
                },
                {
                  path: 'users/:userId',
                  element: <UserDetailsView />,
                },
                {
                  path: 'media',
                  element: <MediaListView />,
                },
                { path: 'media/add', element: <MediaFormView /> },
                { path: 'media/:mediaId', element: <MediaFormView /> },

                {
                  path: 'languages',
                  element: <LanguageListView />,
                },
                { path: 'languages/add', element: <LanguageFormView /> },
                {
                  path: 'languages/:languageId',
                  element: <LanguageDetailsView />,
                },
                {
                  path: 'languages/:languageId/translations/add',
                  element: <TranslationFormView />,
                },
                {
                  path: 'languages/:languageId/translations/:translationId',
                  element: <TranslationFormView />,
                },

                {
                  path: 'promo-codes',
                  element: <PromoCodeListView />,
                },
                { path: 'promo-codes/create', element: <PromoCodeFormView /> },
                {
                  path: 'promo-codes/:promoCodeId',
                  element: <PromoCodeFormView />,
                },
                {
                  path: 'products',
                  element: <ProductListView />,
                },
                {
                  path: 'products/create',
                  element: <ProductFormView />,
                },
                {
                  path: 'products/:productId',
                  element: <ProductFormView />,
                },
                {
                  path: 'product-packages',
                  element: <ProductPackageListView />,
                },
                {
                  path: 'product-packages/create',
                  element: <ProductPackageFormView />,
                },
                {
                  path: 'product-packages/:productPackageId',
                  element: <ProductPackageFormView />,
                },
              ],
            },
            {
              path: 'logs',
              element: <LogListView />,
            },
            {
              path: 'forms',
              children: [
                {
                  path: 'feedback',
                  element: <Feedback />,
                },
                {
                  path: 'contact-page',
                  element: <ContactPage />,
                },
                {
                  path: 'career-page',
                  element: <CareerPage />,
                },
              ],
            },
            {
              path: 'account',
              element: <AccountView />,
            },
          ],
        },
        {
          path: 'app/tickets',
          element: (
            <AuthGuard>
              <TicketLayout />
            </AuthGuard>
          ),
          children: [
            {
              path: '/app/tickets',
              element: <Ticket />,
              children: [
                {
                  path: '/app/tickets/:ticketId',
                  element: <Ticket />,
                },
              ],
            },
          ],
        },
        {
          path: 'docs',
          element: <DocsLayout />,
          children: [
            { element: <Navigate to="/docs/welcome" replace />, index: true },
            { path: 'welcome', element: <WelcomeView /> },
            { path: 'getting-started', element: <GettingStartedView /> },
            { path: 'postman', element: <PostmanView /> },
            { path: 'localization', element: <LocalizationView /> },
            { path: 'products', element: <ProductView /> },
            { path: 'platform', element: <PlatformView /> },
            { path: 'promo-codes', element: <PromoCodeView /> },
            { path: 'tickets', element: <TicketVIew /> },
            { path: 'support', element: <SupportView /> },
            { path: 'changelog', element: <ChangelogView /> },
          ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: '404', element: <NotFoundView /> },
      ],
    },
  ]);
  return element;
}

const Login = Loadable(lazy(() => import('src/views/auth/LoginView')));
const Register = Loadable(lazy(() => import('src/views/auth/RegisterView')));
const NotFoundView = Loadable(
  lazy(() => import('src/views/errors/NotFoundView')),
);
const TicketPlayer = Loadable(lazy(() => import('src/views/ticket/Player.js')));
const Dashboard = Loadable(lazy(() => import('src/views/overview/Dashboard')));
const Activity = Loadable(lazy(() => import('src/views/overview/Activity')));
const Transaction = Loadable(
  lazy(() => import('src/views/overview/Transaction')),
);
const ChartsRevenue = Loadable(
  lazy(() => import('src/views/overview/Charts/Revenue')),
);
const ChartsMRR = Loadable(lazy(() => import('src/views/overview/Charts/MRR')));
const ChartsNewTrials = Loadable(
  lazy(() => import('src/views/overview/Charts/NewTrials')),
);
const ChartsActiveSubscriberBase = Loadable(
  lazy(() => import('src/views/overview/Charts/ActiveSubscriberBase')),
);
const ChartsTrialStartConversion = Loadable(
  lazy(() => import('src/views/overview/Charts/TrialStartConversion')),
);
const ChartsTrialToPaidConversion = Loadable(
  lazy(() => import('src/views/overview/Charts/Trial2PaidConversion')),
);
const ChartsSubscriptionConversion = Loadable(
  lazy(() => import('src/views/overview/Charts/SubscriptionConversion')),
);
const ChartsChurn = Loadable(
  lazy(() => import('src/views/overview/Charts/Churn')),
);
const UserListView = Loadable(
  lazy(() => import('src/views/users/UserListView')),
);
const UserDetailsView = Loadable(
  lazy(() => import('src/views/users/UserDetailsView')),
);
const MediaListView = Loadable(
  lazy(() => import('src/views/media/MediaListView')),
);
const MediaFormView = Loadable(
  lazy(() => import('src/views/media/MediaFormView')),
);
const LanguageListView = Loadable(
  lazy(() => import('src/views/language/LanguageListView')),
);
const LanguageDetailsView = Loadable(
  lazy(() => import('src/views/language/LanguageDetailsView')),
);
const LanguageFormView = Loadable(
  lazy(() => import('src/views/language/LanguageFormView')),
);
const TranslationFormView = Loadable(
  lazy(() => import('src/views/language/TranslationFormView')),
);
const PromoCodeListView = Loadable(
  lazy(() => import('src/views/promoCode/PromoCodeListView')),
);
const PromoCodeFormView = Loadable(
  lazy(() => import('src/views/promoCode/PromoCodeFormView')),
);
const ProductListView = Loadable(
  lazy(() => import('src/views/product/ProductListView')),
);
const ProductFormView = Loadable(
  lazy(() => import('src/views/product/ProductFormView')),
);
const ProductPackageListView = Loadable(
  lazy(() => import('src/views/productPackage/ProductPackageListView')),
);
const ProductPackageFormView = Loadable(
  lazy(() => import('src/views/productPackage/ProductPackageFormView')),
);
const Ticket = Loadable(lazy(() => import('src/views/ticket')));
const LogListView = Loadable(lazy(() => import('src/views/log/LogListView')));
const Feedback = Loadable(lazy(() => import('src/views/forms/Feedback')));
const ContactPage = Loadable(lazy(() => import('src/views/forms/ContactPage')));
const CareerPage = Loadable(lazy(() => import('src/views/forms/CareerPage')));
const AccountView = Loadable(
  lazy(() => import('src/views/account/AccountView')),
);
const WelcomeView = Loadable(lazy(() => import('src/views/docs/WelcomeView')));
const GettingStartedView = Loadable(
  lazy(() => import('src/views/docs/GettingStartedView')),
);
const PostmanView = Loadable(lazy(() => import('src/views/docs/PostmanView')));
const LocalizationView = Loadable(
  lazy(() => import('src/views/docs/LocalizationView')),
);
const ProductView = Loadable(lazy(() => import('src/views/docs/ProductView')));
const PlatformView = Loadable(
  lazy(() => import('src/views/docs/PlatformView')),
);
const PromoCodeView = Loadable(
  lazy(() => import('src/views/docs/PromoCodeView')),
);
const TicketVIew = Loadable(lazy(() => import('src/views/docs/TicketView')));
const SupportView = Loadable(lazy(() => import('src/views/docs/SupportView')));
const ChangelogView = Loadable(
  lazy(() => import('src/views/docs/ChangelogView')),
);
