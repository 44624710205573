import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { DB } from 'src/lib/firebase';
import {
  collection,
  query,
  orderBy,
  doc,
  getDoc,
  getDocs,
  where,
} from 'firebase/firestore';

const collectionName = 'users';

const initialState = {
  list: {
    loading: 'idle',
    users: [],
    error: null,
  },
  selected: {
    isLoaded: false,
    user: null,
    error: null,
  },
};

const slice = createSlice({
  name: collectionName,
  initialState,
  reducers: {
    get(state, action) {
      state.selected.user = action.payload;
    },
    startGetLoad(state, action) {
      state.selected.isLoaded = false;
    },
    endGetLoad(state, action) {
      state.selected.isLoaded = true;
    },
    list(state, action) {
      state.list.users = action.payload;
    },
    startListLoad(state, action) {
      state.list.isLoaded = false;
    },
    endListLoad(state, action) {
      state.list.isLoaded = true;
    },
    reset(state, action) {
      state = initialState;
    },
    setGetError(state, action) {
      state.selected.error = action.payload;
    },
    setListError(state, action) {
      state.list.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const get = id => async dispatch => {
  dispatch(slice.actions.startGetLoad());
  try {
    const docRef = doc(collection(DB, collectionName), id);
    const data = await getDoc(docRef);

    if (data.exists) {
      const subscriptionsDoc = doc(collection(DB, collectionName), id);
      const subscriptionsDocRef = collection(subscriptionsDoc, 'subscriptions');
      const subscriptionsDocQuery = query(
        subscriptionsDocRef,
        orderBy('created_at', 'desc'),
      );
      const subscriptionsRef = await getDocs(subscriptionsDocQuery);

      const transactionsDoc = doc(collection(DB, collectionName), id);
      const transactionsDocRef = collection(transactionsDoc, 'transactions');
      const transactionsDocQuery = query(
        transactionsDocRef,
        orderBy('eventTime', 'desc'),
      );
      const transactionsRef = await getDocs(transactionsDocQuery);

      const userData = _.merge({ id: data.id }, data.data());

      const subscriptions = subscriptionsRef.docs.map(subscription =>
        _.merge({ id: subscription.id }, subscription.data()),
      );

      const transactions = transactionsRef.docs.map(transaction =>
        _.merge({ id: transaction.id }, transaction.data()),
      );

      dispatch(
        slice.actions.get(_.merge({ subscriptions, transactions }, userData)),
      );
    } else {
      dispatch(slice.actions.setGetError('No such user'));
    }
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setGetError(err.message));
  }
  dispatch(slice.actions.endGetLoad());
};

export const search = queryValue => async dispatch => {
  dispatch(slice.actions.startListLoad());
  try {
    let searchUserRef = query(
      collection(DB, collectionName),
      where('email', '==', queryValue),
    );
    searchUserRef = await getDocs(searchUserRef);

    if (searchUserRef.size === 0) {
      searchUserRef = query(
        collection(DB, collectionName),
        where('id', '==', queryValue),
      );
      searchUserRef = await getDocs(searchUserRef);
    }

    dispatch(
      slice.actions.list(
        searchUserRef.docs.map(doc => _.merge({ id: doc.id }, doc.data())),
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(slice.actions.setListError(err.message));
  }
  dispatch(slice.actions.endListLoad());
};

export const reset = () => async dispatch => {
  dispatch(slice.actions.reset());
};

export const getUser = async userId => {
  const docRef = doc(DB, collectionName, userId);
  const user = await getDoc(docRef);

  return user.exists ? user.data() : null;
};

export default slice;
export { collectionName };
